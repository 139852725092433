<template>
  <div>

    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="配置日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="商品ID">
        <el-input v-model="dataForm.id" placeholder="id" ></el-input>
      </el-form-item>

      <el-form-item label="状态">
        <el-select v-model="dataForm.productStatus" clearable placeholder="ALL">
          <el-option v-for="item in productStatusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="币种">
        <el-select v-model="dataForm.currencyType" clearable placeholder="币种">
          <el-option v-for="item in currencyTypeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="生效区域">
        <el-select v-model="dataForm.country" clearable placeholder="ALL">
          <el-option v-for="item in countryOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="配置人">
        <el-select v-model="dataForm.createdBy" clearable placeholder="配置人">
          <el-option v-for="item in sysUser" :key="item.realName" :label="item.realName" :value="item.realName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList(1)" type="primary">查询</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="addOrUpdateHandle(0)" type="danger">新增</el-button>
        <el-button @click="exportList()" type="primary">导出</el-button>

      </el-form-item>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%" :row-class-name="tableRowClassName">
      <el-table-column prop="id" header-align="center" align="center" label="商品ID" width="100">
      </el-table-column>
      <el-table-column prop="productId" header-align="center" align="center" label="第三方ID" width="100">
      </el-table-column>
      <el-table-column prop="thirdPrice" header-align="center" align="center" label="第三方标价">
      </el-table-column>
      <el-table-column prop="currencyType" header-align="center" align="center" label="币种">
      </el-table-column>
      <el-table-column prop="price" header-align="center" align="center" label="端内标价">
      </el-table-column>
      <el-table-column prop="balance" header-align="center" align="center" label="宝石数">
      </el-table-column>
      <el-table-column prop="productStatus" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.productStatus === true" type="text" style="color: rgba(0, 191, 191, 1)" size="small">上架</el-tag>
            <el-tag v-else-if="scope.row.productStatus === false"  type="text" style="color: rgba(236, 128, 141, 1)" size="small">下架</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="description" header-align="center" align="center" label="套餐描述">
      </el-table-column>
      <el-table-column prop="country" header-align="center" align="center" label="所属国家">
      </el-table-column>
      <el-table-column prop="createdBy" header-align="center" align="center" label="配置人">
      </el-table-column>
      <el-table-column prop="createTime" header-align="center" align="center" label="配置时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.productStatus === true" type="danger" 
            @click="statusToggle(scope.row.id, false)">下架
          </el-button>
          <el-button v-else-if="scope.row.productStatus === false" type="success"
            @click="statusToggle(scope.row.id, true)">上架
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList(1)"></add-or-update>

  </div>
</template>

<script>
import { pickerOptions } from "@/views/common/commonOpt";
import AddOrUpdate from "./google-product-edit";

const sourceDataForm = {
  startTime: null,
  endTime: null,
  id: null,
  productStatus: null,
  description: null,
  country: null,
  createdBy: null,
  createTimeArr: [],
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      pickerOptions: pickerOptions,
      countryOpt: [
        {
          value: null,
          label: 'ALL'
        }
      ],
      currencyTypeOpt: [
        {
          value: null,
          label: 'ALL'
        },
        {
          value: 'USD',
          label: 'USD'
        }
      ],
      productStatusOpt: [
      {
          value: null,
          label: 'ALL'
        },
        {
          value: true,
          label: '上架'
        },
        {
          value: false,
          label: '下架'
        }
      ],
      sysUser: [],
      addOrUpdateVisible: true,
    }
  },
  components: {
    AddOrUpdate
  },
  async created() {
    this.sysUser = await this.$store.dispatch("GetSysUser", {});
  },
  activated() {
    this.getDataList(1)
  },
  methods: {tableRowClassName({ row, rowIndex, rowClass }) { 
      console.log(row.productStatus) 
      if (row.productStatus) {  
        return 'active-row';  
      } else {  
        return 'inactive-row';  
      }  
      // 返回空字符串或者默认类名，如果没有匹配的状态  
      return rowClass;  
    },
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList(1)
    },
    // 获取数据列表
    getDataList(page) {
      if (page) {
        this.pageIndex = page
      }
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/google-pay/list-products'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0 && data.result) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList(1)
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList(val)
    },
    // 新增 / 修改
    addOrUpdateHandle(row) {
      this.addOrUpdateVisible = true
      this.$refs.addOrUpdate.getProductIdList()
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(row)
      })
    },
    statusToggle(id, status) {
      this.$confirm('确认进行该操作？', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/google-pay/change-product-status`),
          method: 'post',
          data: this.$http.adornData({
            'id': id,
            'productStatus': status
          })
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList(null)
            this.$message({
              message: '改变状态成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.visible = false
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    exportList() {
      const exportData = {
        ...this.dataForm,
        startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
        this.$http.adornUrl('/admin/google-pay/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
        "_blank"
      );
    },
  }
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
</style>


<style>  
.inactive-row .cell {  
  color: red;  
}  
</style>
